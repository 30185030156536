import React from 'react'
import { SideBar, ContentWrapper } from 'src/layouts/BaseLayout/style'

import CallToAction from 'src/components/CallToAction'
import VariedadeDeOpcoes from '../variedade-de-opcoes/_index'

const Sidebar = () => {
    return (
      <ContentWrapper>
        <VariedadeDeOpcoes />
        <SideBar className='col-12 px-3 pb-md-4 col-lg-4'>
          <div className='position-sticky'>
            <CallToAction
              chat='true'
              account='true'
              section='dobra_02'
              elementName='Abra sua conta e invista'
            />
          </div>
        </SideBar>
      </ContentWrapper>
    )
}

export default Sidebar
