import React from 'react'

const VariedadeDeOpcoes = () => {
  return (
    <section id='variedade-de-opcoes'>
      <div className='container pt-5 pb-xl-5'>
        <div className='row align-items-center'>
          <div className='col-12 col-lg-8'>
            <h2 className='fs-28 lh-32 fs-md-28 mb-4'>Variedade de opções para fazer seu dinheiro render</h2>
            <p>
              No Banco Inter, você pode investir também em Títulos de Outros Emissores, como empresas privadas
              e outras instituições financeiras, que disponibilizam no mercado ativos com diferentes características,
              prazos de vencimento e níveis de risco.
            </p>

            <p className='mb-5'>
              Os riscos são determinados pela saúde financeira do emissor, classificações de agências de risco e eventuais garantias
              atreladas ao título. Por isso, podem ser opções interessantes tanto para os investidores mais conservadores,
              quanto para os mais arrojados.
            </p>

            <h3 className='fs-28 lh-32 fs-md-28 mb-4'>Aqui você tem apoio para investir certo</h3>

            <p className='mb-5'>
              Com ótimo relacionamento e presença no Mercado Financeiro, a Corretora de Títulos do Banco Inter consegue, dentre os
              emissores aprovados por nosso Comitê de Crédito, trazer as melhores oportunidades para os nossos clientes.
            </p>

            <h4 className='fs-28 lh-32 fs-md-28 mb-4'>Confira nossas modalidades de Títulos de Outros Emissores</h4>

            <p>
              <strong>Debêntures</strong><br />
              São instrumentos de captação de recursos  que as empresas utilizam para financiar seus projetos, fazendo do investidor seu credor. São  títulos privados não financeiros de dívida, de médio e longo prazos.
            </p>
            <p>
              <strong>Debênture Incentivadas</strong><br />
              Criadas pelo governo, as Debêntures Incentivadas são emitidas por empresas de infraestrutura ou pesquisa, desenvolvimento e inovação, que isentam os investidores do IR.
            </p>
            <p>
              <strong>CDB - Certificado de Depósito Bancário</strong><br />
              Título emitido pelos bancos para captação de dinheiro para financiar suas atividades de crédito. Ao investir em CDB, você faz um “empréstimo” para o banco em troca da rentabilidade. Geralmente é indexado ao DI, tem prazo de dois anos e conta com a cobertura de até R$ 250 mil do FGC.
            </p>
            <p>
              <strong>LCI - Letra de Crédito Imobiliário</strong><br />
              Título de Renda Fixa emitido por um banco, tornada fonte de recursos para o setor imobiliário, por ter como lastro créditos imobiliários. É remunerada por percentual do CDI, pode ter rentabilidade pré ou pós-fixada, é isenta de IR para pessoas físicas e coberta pelo FGC em até R$ 250 mil por investidor.
            </p>
            <p>
              <strong>LCA - Letra de Crédito do Agronegócio</strong><br />
              Título de Renda Fixa emitido por um banco como empréstimo concedido  ao setor agropecuário. Pode ter rentabilidade pré ou pós-fixada, é remunerada por percentual do CDI, isenta de IR para pessoas físicas e tem garantia do FGC em até R$ 250 mil por conta/CPF.
            </p>
            <p>
              <strong>CRI - Certificado de Recebíveis Imobiliários</strong><br />
              Título lastreado em créditos imobiliários, que transforma recebíveis em ativos financeiros negociáveis à vista, disponibilizando mais recursos para que construtoras, imobiliárias e instituições financeiras apliquem no segmento. Só pode ser emitido por companhias securitizadoras, responsáveis por estruturar os créditos imobiliários e transformá-los em CRIs.
            </p>
            <p className='mb-xl-0'>
              <strong>CRA - Certificado de Recebíveis do Agronegócio</strong><br />
              Lastreado em créditos agrícolas, este título permite viabilizar o financiamento do agropecuário com recursos privados. São emitidos exclusivamente por companhias securitizadoras de direitos creditórios do agronegócio e têm isenção de IR para pessoas físicas que mantenham o papel até o vencimento.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default VariedadeDeOpcoes
